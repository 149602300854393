import _ from "lodash";

type CountryItem = {
  shortName: string;
  acceptLanguage: string;
  timezone: string;
  name: string;
  code: string;
  locale: string;
  htmlLangCode: string;
  phoneCustomerSupport: string;
  langCode: string;
  countryCode: string;
  application: string;
  domain: string;
  testDomain: string;
  validNameSurnameCharacters: string;
  infoEmail: string;
  currency: Currency;
  number: Number;
  pinRequestWaitDuration: Number;
  dateFormats: DateFormats;
  phoneMinLength: string;
  phoneMaxLength: string;
  phonePrefix: string;
  patterns: Patterns;
  loan: Loan;
}

type Loan = {
  duration: number;
  dailyRate: number;
}

type Patterns = {
  postCode: string;
  email: string;
  pin: string;
  otp: string;
  password: string;
  phone: string;
  phoneModel: string;
  allPhone: string;
}


type DateFormats = {
  short: string;
  long: string;
  longWithDay: string;
  shortWithClock: string;
  inputPlaceholder: string;
}

type Number = {
  decimal: string;
  thousand: string;
  precision: number;
}

type Currency = {
  name: string;
  abbr: string;
  symbol: string;
  position: string;
  isSeperate: boolean;
}


const COUNTRY = {
  shortName: "mx",
  acceptLanguage: "es-ES",
  timezone: "America/Mexico_City",
  name: "Mexico",
  code: "mx",
  locale: "Mexico",
  htmlLangCode: "mx",
  langCode: "mx_MX",
  countryCode: "+52",
  application: "MyKredit_MX",
  domain: "https://test.mykredit.mx/",
  testDomain: "https://test.mykredit.mx/",
  infoEmail: "info@mykredit.es",
  phoneCustomerSupport: "+3491 2907100",
  phoneCustomerTollFreeSupport: "+3490 0730072",
  phoneCustomerSupportLabel: "912 907 100",
  phoneCustomerTollFreeSupportLabel: "900 730 072",
  pinRequestWaitDuration: 60,
  currency: {
    name: "Euro",
    abbr: "EUR",
    symbol: "€",
    position: "right",
    isSeperate: false
  },
  number: {
    decimal: ",",
    thousand: ".",
    precision: 2
  },
  dateFormats: {
    short: "DD/MM/YYYY",
    long: "dddd DD MMMM YYYY",
    longWithDay: "dddd DD MMMM YYYY, ddd",
    shortWithClock: "DD/MM/YYYY HH:mm:ss",
    inputPlaceholder: "YYYY/MM/DD"
  },
  phoneMinLength: "10",
  phoneMaxLength: "10",
  phonePrefix: "",
  dniMaxLength: "18",
  dniMinLength: "18",
  validNameSurnameCharacters: "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZzáéíóúüñÁÉÍÓÚÜÑ ",
  patterns: {
    postCode: /^[0-9]{5}$/,
    dni: /^[A-Z]{4}[0-9]{6}[HM][A-Z]{5}[0-9A-Z][0-9]$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    pin: /^[0-9]{4}$/,
    otp: /^[0-9a-zA-Z]{4,6}$/,
    password: /^.{4,20}$/,
    phone: /^[0-9]{10}$/,
    allPhone: /^[0-9]{10}$/
  },
  loan: {
    duration: 20,
    dailyRate: 0.75
  }
};

export default COUNTRY;
